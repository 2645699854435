import {bindable, customElement} from 'aurelia-framework';
import "./time-input.less";
import moment from "moment-timezone";

@customElement('sio-time-input')
export class TimeInput
{
    @bindable config;
    @bindable({defaultBindingMode: 2}) value;

    onlyHours()
    {
        const hours = [];
        for (let i = 0; i < 24; ++i) {
            hours.push({
                label: (i.toString()).padStart(2, "0") + ":00",
                value: (i.toString()).padStart(2, "0") + "00",
            });
        }

        return hours;
    }

    setNow(value)
    {
        const date = moment(value);

        this.value = date.format('HHmm');
    }
}
